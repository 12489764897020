import PropTypes from "prop-types"
import Image from "next/image"

const BannerTitles = ({ title, subtitle, className }) => {
  // サブタイトル内に[url]があった場合はURLの画像に差し替える
  let img = null
  const match = subtitle.match(/\[(https?:\/\/[^\]]+)\]/)
  if (match) {
    img = match[1]
    subtitle = subtitle.replace(match[0], "").trim()
  }

  return (
    <div className={className}>
      <p className="fs-x-large-bold leading-semi-interface lg:fs-4x-large-bold lg:leading-paragraph">
        {title}
      </p>
      <p className="fs-x-large-regular leading-semi-interface lg:fs-4x-large-regular lg:leading-paragraph inline-flex gap-2 flex items-center">
        {subtitle}
        {img ? (
          <span className="relative w-20 h-8 md:w-32 md:h-8 ">
            <Image
              src={img}
              alt="Subtitle Image"
              layout="fill"
              objectFit="contain"
              className="object-contain"
            />
          </span>
        ) : (
          <></>
        )}
      </p>
    </div>
  )
}

BannerTitles.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default BannerTitles
